import './style.styl'
import { sel } from './utils'
import Home from './home'
import Home1 from './home1'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'

const lenis = new Lenis()
lenis.on('scroll', ScrollTrigger.update)
gsap.ticker.add((time) => {
  lenis.raf(time * 1000)
})
gsap.ticker.lagSmoothing(0)

// const _dataPage = sel('.page-wrapper') || sel('body')
const dataPage = sel('.main-wrapper')?.dataset.barbaNamespace || sel('.page-wrapper')?.dataset.page || sel('body')?.dataset.page
// const dataPage = _dataPage?.getAttribute('data-page')
switch (dataPage) {
  case 'home':
    Home()
    break

  case 'home1':
    Home1()
    break
  case 'error':
    Error()
    break
  default:
    console.log('unknown data-page:', dataPage)
}
