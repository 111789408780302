import {
  addSplideClasses,
  addStaticSplideClasses,
  connectSplideArrows,
  connectSplideBullets,
  connectSplideCarouselBullets,
  mm,
  sel,
  selAll,
  splideAutoWidth,
  stResizeObserver,
  vh,
} from './utils'
import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Flip } from 'gsap/Flip'

export default function Home() {
  gsap.registerPlugin(ScrollTrigger, Flip)

  const hero$ = sel('.hero')
  const tiltDiv = sel('.hero__fg__cont')
  const tiltImg1 = sel('.hero__fg__img-w-in.is--h img')
  const tiltImg2 = sel('.hero__fg__img-w-in.is--f img')
  const heroBg$ = sel('.hero__bg__svg')
  const heroInfo$ = sel('.hero__info')
  const heroImgI$ = sel('.hero__fg__img-w.is--i')
  const heroImgG$ = sel('.hero__fg__img-w.is--g')
  const heroImgB$ = sel('.hero__fg__img-w.is--b')

  const heroTilt = { max: 30 }
  const heroTl = gsap.timeline({ defaults: { duration: 1, ease: 'none' } })
  heroTl.to(heroTilt, { max: 0 }).to([heroImgI$, heroImgB$], { y: '40vh' }, 0).to(heroImgG$, { y: '10vh' }, 0).to(heroInfo$, { y: '20vh' }, 0)
  mm.add('(min-width: 767px)', () => {
    heroTl.to(heroBg$, { y: '60vh', opacity: 0 }, 0)
  })

  ScrollTrigger.create({
    trigger: hero$,
    start: 'top top',
    end: 'bottom top',
    scrub: true,
    animation: heroTl,
  })

  let mouseX = 0,
    mouseY = 0

  mm.add('(min-width: 767px)', () => {
    function updateMousePosition(e) {
      const rect = hero$.getBoundingClientRect()
      mouseX = e.clientX - (rect.left + rect.width / 2)
      mouseY = e.clientY - (rect.top + rect.height / 2)
    }

    function updateTilt() {
      const percentX = mouseX / (hero$.offsetWidth / 2)
      const percentY = -mouseY / (hero$.offsetHeight / 2)

      const tiltX = heroTilt.max * percentY
      const tiltY = heroTilt.max * percentX
      const mouseLeft = tiltX === 0 && tiltY === 0 ? true : false

      gsap.to([tiltDiv, tiltImg1, tiltImg2], {
        rotationX: tiltX,
        rotationY: tiltY,
        duration: mouseLeft ? 5 : 1,
      })
    }

    hero$.addEventListener('mousemove', updateMousePosition)
    hero$.addEventListener('mouseleave', () => {
      mouseX = mouseY = 0
    })
    gsap.ticker.add(updateTilt)
  })

  const heroImg1$ = sel('.hero__fg__img-w-in.is--h')
  const heroImg2$ = sel('.hero__fg__img-w-in.is--f')

  const projectImgWA$ = selAll('.projects__img-w')

  let tl
  ;[projectImgWA$[0], projectImgWA$[1]].forEach((imgW, i) => {
    imgW.style.backgroundColor = 'transparent'
    imgW.querySelector('img')?.remove()
  })

  function resizeHandler() {
    tl && tl.revert()
    const stateInit = Flip.getState([heroImg1$, heroImg2$], {
      props: 'width,height,top,left',
    })
    projectImgWA$[0].appendChild(heroImg1$)
    projectImgWA$[1].appendChild(heroImg2$)

    tl = gsap.timeline({
      scrollTrigger: {
        trigger: hero$,
        start: 'top top',
        end: 'bottom+=' + vh(30) + ' top',
        scrub: true,
        ease: 'none',
        onUpdate: (self) => {},
      },
      defaults: { duration: 1 },
    })
    tl.add(
      Flip.from(stateInit, {
        duration: 1,
        ease: 'power1.out',
      }),
      0
    )
  }
  // append child have to be after the cmsload init
  window.fsAttributes = window.fsAttributes || []
  window.fsAttributes.push([
    'cmsload',
    (listInstances) => {
      const [listInstance] = listInstances
      if (!listInstance.list.className.includes('projects__list')) return
      resizeHandler()
      window.addEventListener('resize', resizeHandler)

      // The `renderitems` event runs whenever the list renders items after switching pages.
      listInstance.on('renderitems', (renderedItems) => {
        // console.log(renderedItems)
      })
    },
  ])
  // window.fsAttributes.cmsload.destroy()
  // window.fsAttributes.cmsload.init()
  // window.fsAttributes.reset('cmsload')

  const serviceSec = sel('.services-sec')
  const serviceImgW$ = sel('.services__img-w')
  const serviceImgA$ = selAll('.services__img__img-w')

  serviceImgW$.style.opacity = 0
  ScrollTrigger.create({
    trigger: serviceSec,
    start: 'top 80%',
    once: true,
    onEnter: () => {
      gsap.from(serviceImgA$, {
        duration: 1,
        opacity: 0,
        x: 300,
        rotateZ: 10,
        ease: 'power2.out',
        stagger: 0.2,
      })
      gsap.to(serviceImgW$, { opacity: 1, duration: 1 })
    },
  })

  function testSliderInit() {
    const classPrefix = 'testimonials'
    const _sliderA = classPrefix + '__slider-a'
    const _sliderB = classPrefix + '__slider-b'
    ;[_sliderA, _sliderB].forEach((_slider) => {
      addSplideClasses(_slider)
      const slider = new Splide('.' + _slider, {
        direction: _slider === _sliderA ? 'ltr' : 'rtl',
        arrows: false,
        pagination: false,
        gap: '1.25rem',
        type: 'loop',
        fixedWidth: '36rem',
        autoScroll: { speed: 0.6, autoStart: false },
        speed: 1500,
        easing: 'cubic-bezier(0.16, 1, 0.3, 1)',

        breakpoints: {
          767: {
            perPage: 1,
            fixedWidth: '90%',
          },
        },
      })

      splideAutoWidth(slider)
      slider.mount({ AutoScroll })
    })
  }
  testSliderInit()

  mm.add('(max-width: 767px)', () => {
    const logoSlider$ = sel('.logos__slider')
    console.log(logoSlider$)
    addStaticSplideClasses(logoSlider$)
    const splide = new Splide(logoSlider$, {
      arrows: false,
      pagination: false,
      gap: '1rem',
      type: 'loop',
      // fixedWidth: '12rem',
      autoWidth: true,
      // autoHeight: true,
      autoScroll: { speed: 0.6 },
      easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
      breakpoints: {
        767: {
          // perPage: 1,
          // fixedWidth: '90%',
        },
      },
    })
    // splideAutoWidth(splide)
    splide.mount({ AutoScroll })
  })

  const compare$ = sel('.compare__mod')
  const compareItem$A = selAll('.compare__item .row-0-5')
  ScrollTrigger.create({
    trigger: compare$,
    start: 'top 75%',
    animation: gsap.from(compareItem$A, {
      duration: 1.5,
      x: 50,
      opacity: 0,
      ease: 'expo.out',
      stagger: 0.05,
    }),
  })

  const planLo$ = sel('.plan__lo')
  const planItem$ = selAll('.plan__item')
  ScrollTrigger.create({
    trigger: planLo$,
    start: 'top 75%',
    animation: gsap.from(planItem$, {
      duration: 2,
      y: 50,
      opacity: 0,
      ease: 'expo.out',
      stagger: 0.2,
    }),
  })

  const footer$ = sel('.footer')
  const footerC$ = footer$.querySelector('.footer__c')
  gsap.set(footerC$, { y: '-100%' })
  ScrollTrigger.create({
    trigger: footer$,
    start: 'top bottom',
    end: 'bottom bottom',
    pin: footerC$,
    pinSpacing: false,
    scrub: true,
  })

  stResizeObserver()
}
