import { mm, sel, selAll, vh } from './utils'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Flip } from 'gsap/Flip'

export default function Home1() {
  gsap.registerPlugin(ScrollTrigger, Flip)

  const hero$ = sel('.hero')
  const tiltFgW$ = sel('.hero__fg-w')
  const tiltDiv = sel('.hero__fg__cont')
  const tiltImg1 = sel('.hero__fg__img-w-in.is--h')
  const tiltImg2 = sel('.hero__fg__img-w-in.is--f')
  const heroBg$ = sel('.hero__bg__svg')
  const heroInfo$ = sel('.hero__info')
  const heroImgI$ = sel('.hero__fg__img-w.is--i')
  const heroImgG$ = sel('.hero__fg__img-w.is--g')
  const heroImgB$ = sel('.hero__fg__img-w.is--b')
  const projectsImgW$a = selAll('.projects__img-w')

  gsap.set([tiltFgW$, tiltDiv, tiltImg1, tiltImg2, projectsImgW$a[0], projectsImgW$a[1]], { perspective: 2500, transformStyle: 'preserve-3d' })
  gsap.set([tiltImg1, tiltImg2], { perspective: 9500, transformStyle: 'preserve-3d' })
  const t1 = 10000

  const tiltImg1C$ = tiltImg1.querySelector('.collection-list-wrapper')
  const tiltImg2C$ = tiltImg2.querySelector('.collection-list-wrapper')

  gsap.set(tiltImg1C$, { rotateY: 30 })
  gsap.set(tiltImg2C$, { rotateY: -30 })

  gsap.set(tiltImg1, { transformOrigin: `30vh -10vh` })
  gsap.set(tiltImg2, { transformOrigin: `-30vh -1vh` })
  const heroTilt = { max: 10 }

  const heroTl = gsap.timeline({ defaults: { duration: 1, ease: 'none' } })

  heroTl.to(heroTilt, { max: 0 }).to([heroImgI$, heroImgB$], { y: '40vh' }, 0).to(heroImgG$, { y: '10vh' }, 0).to(heroInfo$, { y: '20vh' }, 0)
  mm.add('(min-width: 767px)', () => {
    heroTl.to(heroBg$, { y: '60vh', opacity: 0 }, 0)
    heroTl.to([tiltImg1C$, tiltImg2C$], { rotateY: 0 }, 0)
  })

  ScrollTrigger.create({
    trigger: hero$,
    start: 'top top',
    end: 'bottom top',
    scrub: true,
    animation: heroTl,
  })

  let mouseX = 0,
    mouseY = 0

  mm.add('(min-width: 767px)', () => {
    function updateMousePosition(e) {
      const rect = hero$.getBoundingClientRect()
      mouseX = e.clientX - (rect.left + rect.width / 2)
      mouseY = e.clientY - (rect.top + rect.height / 2)
    }

    function updateTilt() {
      const percentX = mouseX / (hero$.offsetWidth / 2)
      const percentY = -mouseY / (hero$.offsetHeight / 2)

      const tiltX = heroTilt.max * percentY
      const tiltY = heroTilt.max * percentX
      const mouseLeft = tiltX === 0 && tiltY === 0 ? true : false

      gsap.to([tiltDiv, tiltImg1, tiltImg2], {
        rotationX: tiltX,
        rotationY: tiltY,
        duration: mouseLeft ? 5 : 1,
      })
    }

    hero$.addEventListener('mousemove', updateMousePosition)
    hero$.addEventListener('mouseleave', () => {
      mouseX = mouseY = 0
    })
    gsap.ticker.add(updateTilt)
  })

  const heroImg1$ = sel('.hero__fg__img-w-in.is--h')
  const heroImg2$ = sel('.hero__fg__img-w-in.is--f')

  const projectImgWA$ = selAll('.projects__img-w')

  let tl
  ;[projectImgWA$[0], projectImgWA$[1]].forEach((imgW, i) => {
    imgW.style.backgroundColor = 'transparent'
    imgW.querySelector('img')?.remove()
  })

  function resizeHandler() {
    tl && tl.revert()
    const stateInit = Flip.getState([heroImg1$, heroImg2$], {
      props: 'width,height,top,left',
    })
    projectImgWA$[0].appendChild(heroImg1$)
    projectImgWA$[1].appendChild(heroImg2$)

    tl = gsap.timeline({
      scrollTrigger: {
        trigger: hero$,
        start: 'top top',
        end: 'bottom+=' + vh(30) + ' top',
        scrub: true,
        ease: 'none',
        onUpdate: (self) => {},
      },
      defaults: { duration: 1 },
    })
    tl.add(
      Flip.from(stateInit, {
        duration: 1,
        ease: 'power1.out',
      }),
      0
    )
  }
  // hero/projects append child have to run after the cmsload init
  window.fsAttributes = window.fsAttributes || []
  window.fsAttributes.push([
    'cmsload',
    (listInstances) => {
      const [listInstance] = listInstances
      if (!listInstance.list.className.includes('projects__list')) return
      resizeHandler()
      window.addEventListener('resize', resizeHandler)
    },
  ])
}
